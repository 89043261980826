.link-underline-grow {
  display: inline-block;
  text-decoration: none;
  &:after {
    content:"";
    display: block;
    width:100%;
    height:2px;
    background-color: #000;
    @include transition_all();
    transform-origin: top center;
  }
  &:hover {
    &:after {
      transform: scaleY(2);
    }
  }
}
.link-underline-leftright {
  cursor:pointer;
  $transTime:250ms;
  $transEaseInOut:all $transTime ease-in-out;
  $transEaseIn:all $transTime ease-in;
  $transEaseOut:all $transTime ease-out;
  $transOff:all 0ms ease-in-out;
  color:#fff;
  transition: $transEaseInOut;
  display: inline-block;
  text-decoration: none;
  overflow: hidden;
  padding-bottom: 3px;
  &:before,
  &:after {
    content:"";
    position: absolute;
    @include calc(top,'100% - 4px');
    @include calc(left,'-100%');
    display: block;
    width:100%;
    height:1px;
    background-color: #fff;
    transform-origin: top center;
  }
  &:before {
    transition: $transOff;
  }
  &:after {
    @include calc(left,'100%');
    transition: $transEaseOut;
  }
  &.active {
    //color:$color-cyan;
    &:before {
      @include calc(left,'-100% + 20px');
    }
    &:after {
      @include calc(left,'-100% + 20px');
    }
  }
  &:hover {
    //color:$color-cyan;
    &:before {
      left:0;
      transition: $transEaseIn;
    }
    &:after {
      transition: $transEaseOut;
      transition-delay: $transTime;
      left:0;
    }
  }
  &.small {
    &:before,
    &:after {
      height:1px;
    }
  }
}
.link-underline-leftright-dark {
  @extend .link-underline-leftright;
  &:before,
  &:after {
    background-color: $color-red;
  }
}
.link-underline-leftright-red {
  @extend .link-underline-leftright;
  &:before,
  &:after {
    background-color: $color-red;
  }
}
.link-underline-leftright-white {
  @extend .link-underline-leftright;
  &:before,
  &:after {
    background-color: #fff;
  }
}

.miniButton {
  color:#000;
  @include font-helvetica-bold();
  font-size:12px;
  text-transform: uppercase;
  background-color: #ccc;
  border:1px solid #000;
  display: inline-block;
  margin-top:5px;
  padding:5px 5px 2px;
  @include transition-all();
}
button.only-mini {
  background-color: transparent;
  border: none;
  display: inline-block;
  .miniButton {
    cursor: pointer;
    margin-top:0;
    &:hover {
      background-color: #fff;
    }
  }
}



//elipse
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link-underline-grow {
  color:$color-red;
  @include font-helvetica-bold();
  font-size:18px;
  line-height: 22px;
  text-transform: uppercase;
  @media only screen and (max-width: $breakpoint-mobile-nav) {
    font-size:15px;
    line-height: 15px;
  }
}

.generic-image-hover {
  cursor: pointer;
  opacity:1;
  @include transition-opacity();
  &:hover {
    opacity:0.85;
  }
}
.scrim {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: rgb(26,98,137);
  background: linear-gradient(180deg, rgba(26,98,137,1) 0%, rgba(7,64,96,1) 68%, rgba(0,55,85,1) 100%);
}


.mini-nav-action {
  .mini-nav-action2 {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    * {
      @include font-montserrat-bold();
      font-size: 16px;
      color:#fff;
    }
  }
  a {
    svg {
      margin-left: 5px;
      width:12px;
      path {
        fill: #fff;
      }
      @include transition-all();
    }
    &:hover {
      svg {
        transform: translateX(5px);
      }
    }
  }
  &.back {
    a {
      svg {
        margin-right: 5px;
        margin-left: 0;
      }
      &:hover {
        svg {
          transform: translateX(-5px);
        }
      }
    }
  }
}

ul.sidenav-links {
  list-style: none;
  overflow: hidden;
  width:340px;
  @extend .noselect;
  li {
    display: flex;
    @include font-montserrat-bold();
    font-size: 20px;
    @include transition-all();
    transform: translateX(-30px);
    margin:20px 0;
    a, span {
      @include transition-all();
      text-transform: uppercase;
      color: #fff;
      opacity: 0.5;
    }
    svg {
      width:14px;
      @include transition-all();
      opacity: 0.5;
      margin-right: 18px;
      path {
        fill:#fff;

      }
    }
    &.active,
    &:hover {
      transform: translateX(0);
      a, svg, span {
        opacity: 1;
      }
    }

  }
}


.global-close {
  width:40px;
  height: 40px;
  display: block;
  cursor: pointer;
  &:before, &:after {
    content:"";
    width:40px;
    height: 5px;
    border-radius: 7px;
    background-color: #fff;
    position: absolute;
    top:16px;
    left:0;
    @include transition-all();
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
  &:hover {
    &:before, &:after {
      transform: rotate(0deg);
      background-color:$color-teal;
    }
  }
}


.below-header {
  padding-top: $headerTall;
  @media only screen and (max-width: $breakpoint-mobile-nav) {
    //padding-top: $headerSmall;
    padding-top: 0;
  }
}



.field-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  align-items: flex-start;
  .field-row-element {
    width:45%;
    display: flex;
    align-items: center;

    &.guests {
      display: block;
      #friends {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .field-row-element {
          width: 100%;
          margin-bottom: 20px;
          .guests-close {
            position: absolute;
            top:10px;
            right:-25px;
            color:#fff;
            cursor: pointer;
            @include font-montserrat-bold();
          }
        }
      }
    }
    .lbl {
      color:#fff;
      @include font-montserrat-bold();
      margin-right: 15px;
    }
    input {
      padding:10px 20px;
      @include calc(width,'100% - 40px');
      color:#fff;
      @include font-helvetica-regular();
      font-size: 18px;
      background-color: $color-teal-dk2;
      border: 1px solid $color-teal;
      @include set-input-placeholder-color(#fff);
      &.error {
        border: 1px solid $color-error;
      }
    }
    select {
      -webkit-appearance:none;
      cursor: pointer;
      padding:10px 30px 10px 20px;
      width:100%;
      color:#fff;
      @include font-helvetica-regular();
      background-color: $color-teal-dk2;
      border: 1px solid $color-teal;
      font-size: 18px;
      border-radius: 0;
      background-image: url(../images/global/icon-angle-down.svg);
      background-repeat: no-repeat;
      background-position: 95% 50%;
      background-size: 14px 14px;
      &.error {
        border: 1px solid $color-error;
      }
    }
  }
}
