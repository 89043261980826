.footer {
  margin:140px 0 40px;
  .inner {

    body.page-home & {
      padding:0 20px;
    }
    text-align: right;
  }
  * {
    color:#fff;
    font-size: 14px;
    line-height:1.4em;
    @include font-oswald-light();
    text-transform: uppercase;
  }
  ul {
    list-style: none;
    li {
      list-style: none;
      display: inline;
      margin-left: 10px;
    }
  }
  .line {
    margin:15px 0;
  }
  a {
    @include transition-all();
    border-bottom: 1px solid rgba(255,255,255,0);
    &:hover {
      color:$color-teal;
      border-bottom: 1px solid $color-teal;
    }
  }
}
body.home {
  @media only screen and (max-width: $breakpoint-small) {
    .footer {
      margin: 40px 0 40px;
    }
  }
}
