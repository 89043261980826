body.music-hide {
  .header .screenings .line,
  .header .screenings .mini-nav-action {
    display: none;
  }
  .mini-nav-action {
    display: none;
  }
  .film-blocks .film-block {
    display: none;
  }

  #soundtrack.film-block,
  #score.film-block {
    display: block;
    padding-top: 0;
  }
  .sidenav-links {
    li {
      display: none;
    }
    #filmnav-soundtrack,
    #filmnav-score {
      display: flex;
    }
  }
}