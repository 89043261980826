body.home {
  .sunset-site {
    @include vh100();
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  a.panel-a {
    display: block;
    .icon {
      position: absolute;
      bottom:0;
      left:0;
      cursor: pointer;
      width: 132px;
      height: 132px;
      background-color: $color-blue;
      display: flex;
      align-items: center;
      justify-content: center;
      @include transition-all();
      svg {
        width:50px;
        @include transition-all();
        path {
          fill:#fff;
        }
      }
      @media only screen and (max-width: $breakpoint-small) {
        width: 80px;
        height: 80px;
        svg {
          width:30px;
        }
      }
    }
    &:hover {
      .icon {
        background-color: $color-teal-dk;
        svg {
          transform: translate(5px, -5px);
        }
      }
    }
  }
  .mobile-fyc {
    display: none;
    @media only screen and (max-width: $breakpoint-small) {
      display: block;
      padding-top: $headerSmall;
      margin-top: 20px;
    }
  }

  .mobile-swiper {
    display: none;
    @media only screen and (max-width: $breakpoint-small) {
      display: block;
    }
    margin-top: 30px;
    .swiper-wrapper, .swiper-slide {
      width:100%;
    }
    .swiper-slide {
      aspect-ratio: 16/9;
      a.panel-a {
        position: absolute;
        bottom:0;
        left:0;
      }

      .flx {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        text-align: center;
        img {
          width:75%;
        }
      }

    }
    .mobile-films {
      margin-top: 10px;
    }
  }

  .fyc {
    h2 {
      @include font-oswald-light();
      font-size: 70px;
      text-transform: uppercase;
      color:#fff;
      display: inline-block;
      @media only screen and (max-width: $breakpoint-medium) {
        font-size: 60px;
      }
      @media (max-height: 800px) {
        font-size: 40px;
      }
      @media (max-height: 600px) {
        font-size: 30px;
      }

      @media only screen and (max-width: $breakpoint-small) {
        font-size: 40px;
      }

      .line {
        width: 100%;
        height: 1px;
        background-color: $color-teal;
        display: block;
        margin:40px 0 20px;
      }
    }
  }

  .content-lr {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    .sidebar {
      width:40%;
    }
    .panels {
      width:60%;
    }
    @media only screen and (max-width: $breakpoint-small) {
      display: none;
      flex-wrap: wrap;
      flex-direction: column-reverse;
      margin-top: 40px;
      .sidebar {
        width:100%;
      }
      .panels {
        width:100%;
      }
    }
  }

  .panels {
    z-index:10001; //go above header - its ok
    @media only screen and (max-width: $breakpoint-small) {
      display: none;
    }
    .panel {
      width:100%;
      height: 0;
      padding-top: 73%;


      &.ampas {
        display: flex;
        align-items: center;
        justify-content: center;
        //padding-top: 10vh;
        //height: 30vh;
        //&.ampas-2 {
          //height: 35vh;
        //}
        padding-top: 0;
        height: 52vh;

        text-align: center;
        a {
          display: inline;
        }
        img {
          width:60%;
          height: auto;
        }
      }

    }

  }

  .sidebar {
    position: fixed;
    left:var(--offsetLeft);
    top:0;
    @include vh100();


    @media only screen and (max-width: $breakpoint-small) {
      position: relative;
      left: 0;
      height: auto;
      min-height: auto;
      margin: 40px 0;

      .fyc {
        display: none;
      }
    }
    .sidebar2 {
      padding-top:90px;
      height: calc(100vh - 90px);
      display: flex;
      align-items: center;
      @media (max-height: 600px) {
        padding-top: 40px;
        height: calc(100vh - 40px);
      }
      @media only screen and (max-width: $breakpoint-small) {
        height: auto;
        padding-top: 0;
      }
    }

    .mini-nav-action {
      margin-bottom: 20px;
      @media only screen and (max-width: $breakpoint-small) {
        display: none;
      }
    }




  }
}
