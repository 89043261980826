header.header {
  top:0;
  left:0;
  width:100%;
  position: fixed;
  @include transition-all();
  z-index: 10000;
  height: $headerTall;
  .capper, .capper-padded {
    height: 100%;
  }

  .lr {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      width:325px;
      body.page-home & {
        @media (max-height: 600px) {
          top:-30px;
        }
      }

      @media only screen and (max-width: $breakpoint-medium) {
        width:275px;
      }
      img {
        width: 100%;
      }
    }

    .rt {
      @media only screen and (max-width: $breakpoint-mobile-nav) {
        display: none;
      }
    }

    .screenings  {
      @extend .noselect;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: -8px;

      .txt {
        color:#fff;
        @include font-oswald-light();
        font-size: 50px;
        text-transform: uppercase;
        @media only screen and (max-width: $breakpoint-medium) {
          font-size: 40px;
        }
      }
      .line {
        height: 50px;
        width:1px;
        background-color: $color-teal;
        display: block;
        margin: 0 20px;
      }
    }
  }
  background-color: $color-blue-dk;
  &.header-home {
    background-color: transparent;
  }

  .dropdowns {
    margin-top: 10px;
    .field-row {
      margin-bottom: 0!important;
    }
  }
}

#mobile-nav-overlay-outer {
  margin-top: $headerSmall;
}
body.page-screenings.miniheader,
body.page-film.miniheader {

  header.header {
    height: $headerSmall;
  }
  .sidebar {
    top:$headerSmall;
  }
  #mobile-nav-overlay-outer {
    margin-top: $headerSmall;
  }
}

@media only screen and (max-width: $breakpoint-mobile-nav) {
  header.header {
    height: $headerSmall;
    background-color: $color-blue-dk!important;
  }
}

body.page-home {
  header.header {
    pointer-events: none;
    @media only screen and (max-width: $breakpoint-mobile-nav) {
      pointer-events: all;
    }
  }
}