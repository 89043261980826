.film-block.synopsis {
 .synopsis-lr {
   display: flex;
   justify-content: space-between;
   .rte {
     h2 {
       margin-bottom: 40px!important;
     }
   }
   .mobile-poster {
     display: none;
     @media only screen and (max-width: $breakpoint-small) {
       display: block;
       margin-bottom: 40px;
       img {
         width:100%;
       }
     }
   }
   .poster {
     @media only screen and (max-width: $breakpoint-small) {
       display: none;
     }
     max-width: 400px;
     width:40%;
     margin-right: 40px;
     img {
       width: 100%;
     }
   }
 }
}

