body.film {
  $heightBreak1:700px;
  .site-content {
    padding-top:60px;
  }
  .content-lr {
    display: flex;
    justify-content: flex-end;
    padding-top: $headerTall;
    .film-blocks {
      width: 60%;
      @media only screen and (max-width: $breakpoint-small) {
        width: 100%;
      }
      .film-blocks2 {
        min-height: calc(100vh - 500px);
      }
    }
    @media only screen and (max-width: $breakpoint-small) {
      padding-top: 0;
    }
  }
  .sidebar {
    @media only screen and (max-width: $breakpoint-small) {
      display: none;
    }
    max-width: 680px;
    width: 40%;

    z-index: 2;
    position: fixed;
    left:var(--offsetLeft);
    top:$headerTall;
    @include transition-all();
    padding-top: 60px;
    @media only screen and (max-height: $heightBreak1) {
      padding-top: 20px;
    }
    .logo {
      width:75%;
      img {
        width:100%;
      }
    }
    .mini-nav-action {
      margin: 60px 0 40px;
      @media (max-height: 760px) {
        margin: 30px 0 20px;
      }
    }
    .line {
      width:100%;
      height: 1px;
      display: block;
      background-color:$color-teal;
      margin-bottom: 20px;
    }
  }

  .film-blocks {
    .film-block {
      @extend .noselect;
      padding-top: $headerTall;
      &.synopsis {
        padding-top: 0;
      }
      //margin-bottom: 20vh;
      @media only screen and (max-width: $breakpoint-small) {
        margin-bottom: 40px;
        padding-bottom: 40px;
        border-bottom: 1px solid $color-teal;
      }
      .rte {
        h2 {
          margin-bottom: 50px;
        }
      }
    }
    @media only screen and (max-height: $heightBreak1) {
      .fil-blocks2 {
        margin-top: -15px;
      }
    }
  }

  .mobile-topbar {
    display: none;
    @media only screen and (max-width: $breakpoint-small) {
      display: block;
      margin-top: $headerSmall;
      .fyc-label {
        margin: 40px 0 20px;
        text-transform: uppercase;
        font-size: 24px;
        @include font-oswald-light();
        color:#B9B9B9;
      }
    }
  }
}
