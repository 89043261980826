/////////////////////////////////////////////////////////
//GLOBAL TAGS
/////////////////////////////////////////////////////////
html, body {
  background-color: #000;
  scroll-behavior: smooth;
}

body {
  @include vh100();
}

#container {
  width: 100%;
}

#content {
  @include vh100();
}

#meta-sitewidth {
  display: none;
  z-index: 0;
  padding-top: $max-width-site;
}
#meta-breakpoint {
  display: none;
  z-index: 0;
  padding-top: $breakpoint-small;
}

a {
  text-decoration: none;
}

.generic-holder {
  @include master-padding-tb();
  min-height: 300px;

  .generic-holder-content {
    padding-top: 40px;
  }
}

.blocks-holder {
  width: 100%;
}
.block {
  width:100%;
  @include blocks-margin-tb();
}
