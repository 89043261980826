.film-block.soundtrack {
  $soundtrack_mid_breakpoint:1250px;
  $soundtrack_large_breakpoint:1450px;
  $scrubbarSize:18px;
  .soundtrack-module {

    #menu-sound-bars {
      height: 30px;
      left: 8px;
      position: absolute;
      top: 31px;
      width: 40px;

      .bar {
        background: #000;
        top: 0;
        position: absolute;
        width: 3px;
        opacity: 1;
        height: 15px;
        @include willChange();
        animation: sound 0ms -800ms linear infinite alternate;
      }

      &.unmuted {
        .bar {}
      }

      &.muted {
        .bar {
          animation: none 0ms -800ms linear infinite alternate;
          transform: scaleY(0.1);
        }
      }

      @keyframes sound {
        0% {
          //opacity: .35;
          opacity: 1;
          height: 15px;
          transform: scaleY(0.2);
        }

        100% {
          opacity: 1;
          height: 15px;
          transform: scaleY(1);
        }
      }

      .bar:nth-child(1) {
        left: 1px;
        animation-duration: 474ms;
      }

      .bar:nth-child(2) {
        left: 5px;
        animation-duration: 433ms;
      }

      .bar:nth-child(3) {
        left: 9px;
        animation-duration: 407ms;
      }

      .bar:nth-child(4) {
        left: 13px;
        animation-duration: 458ms;
      }

      .bar:nth-child(5) {
        left: 17px;
        animation-duration: 400ms;
      }

      .bar:nth-child(6) {
        left: 21px;
        animation-duration: 427ms;
      }

      .bar:nth-child(7) {
        left: 25px;
        animation-duration: 441ms;
      }

      .bar:nth-child(8) {
        left: 29px;
        animation-duration: 419ms;
      }
    }


    .soundtrack-list {
      ul {
        width:100%;
        li {
          border-top:2px solid rgba(0,0,0,0);
          border-bottom:2px solid rgba(0,0,0,0);
          &:last-child {
            border-bottom: none;
          }

          padding:20px 0;
          list-style: none;
          //cursor:pointer;
          margin:0 0 20px 0;
          min-height: 30px;
          &:last-child {
            margin-bottom:0;
          }
          @include transition_all();
          background-color: rgba(20, 20, 20, 0);

          width:100%;
          height: auto;
          //overflow-x: hidden;
          //overflow-y: visible;
          .shifter {
            width:100%;
            top:0;
            left:0;
            //left:-40px;
            @include transition_all();
            display:block;
            .l1 {
              display: flex;
            }

          }

          .icon {
            min-width:28px;
            max-width:28px;
            height:28px;
            margin-right:10px;
            cursor:pointer;
            opacity:1;
            @include transition_all();
            display: inline-block;
            svg {
              path {
                fill:#fff;
                @include transition_all();
              }
            }
            &:hover {
              svg {
                path {
                  fill:$color-teal;
                }
              }
            }
          }
          .icon.right {
            position: absolute;
            top:5px;
            right:0;
            margin-right: 0;
            visibility: hidden;
            opacity: 0;
            @include transition_all();
          }
          .txt-holder {
            @include calc(width,'100% - 48px');
            border-radius: 50px 0 0 50px;
            padding:0;
          }
          &.state_paused {
            .play {
              display:block;
            }
            .pause {
              display:none;
            }
          }
          &.state_playing {
            .play {
              display:none;
            }
            .pause {
              display:block;
            }
          }

          .txt {
            left:10px;
            @include font-oswald-medium();
            line-height: 1.1em;
            color:#fff;
            display: block;
            @include transition_all();
            text-transform: uppercase;
            &.title {
              color:#fff;
              font-size:24px;
            }
            &.performer {
              font-size:18px;
              color:#B9B9B9;
              margin-top: 10px;
            }
          }

          &.state_playing {
            .txt {
              opacity:1;
            }
          }

          .l2 {
            top:-40px;
            margin-top: 15px;
            margin-left: 28px;
            @include transition-all();
          }


          &.active {
            .icon.right {
              visibility: visible;
              opacity: 1;
            }
            .icon {
              min-width:60px;
              max-width:60px;
              height:60px;
            }
            .l2 {
              top:0;
              margin-left: 0;
            }
          }
          border-bottom:1px solid $color-teal;
          &:last-child {
            border: none;
          }
        }
      }
    }


    .soundtrack-player {
      .soundtrack-player-inner {
        height: 100%;
      }
      .graphic {
        margin-top: 20px;
        min-height: 20px;
        left:10px;
        //@include calc(width,'100% - 100px');
        width:100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .span {
          font-size: 20px;
          @include font-helvetica-bold();
          color:#FFFFFF;
          letter-spacing: 0.1em;
        }
        .txt {
          @include font-helvetica-bold();
          color:#fff;
          font-size:18px;
          line-height:18px;
          width:100px;
          min-height:1px;
          display: none!important;//hide times
          &.left {
            text-align: left;
          }
          &.right {
            text-align: right;
          }
        }
      }

      .lines {
        margin:10px 0 0 0;
        width:100%;
        img {
          width:100%;
          height:auto;
        }
      }

      .scrubbar {
        @include calc(width,'100% - 120px');
        height:6px;
        background-color:$color-blue-dk;
        display: block;

        .scrubbar-pct {
          position: absolute;
          top:0;
          left:0;
          background-color: $color-teal;
          height:100%;
          width:0;
          display: block;
          @include transition_all();
        }
        .scrubbar-dot-container {
          position: absolute;
          top:-8px;
          left:0;
          width:100%;
          height:$scrubbarSize;
          display: none!important;//dont use this
        }
        .scrubbar-dot {
          position: absolute;
          top:0;
          left:0;
          width:100%;
          height:$scrubbarSize;
          .scrubbar-dot-inner {
            position: absolute;
            top:0;
            left:-9px;
            width:$scrubbarSize;
            height:$scrubbarSize;
            border-radius: 9px;
            background-color: $color-teal;
            //cursor: pointer;
            display: block;
            &.notdragged {
              @include transition_all();
              cursor: pointer;
            }
            &:hover {
              background-color:#fff;
            }
          }
        }
      }
    }

  }
}

