//use in floats
.clearfix:after {
	content: " ";
	display: block;
	height: 0;
	clear: both;
}

/* add this clas to kill a css transition in progress, then remove it again */
.kill-css-transition {
	-webkit-transition: all 0ms ease!important;
    -moz-transition: all 0ms ease!important;
   	-o-transition: all 0ms ease!important;
    transition: all 0ms ease!important;
}

//stop an element and its children from being selectable
.noselect {
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

//vertical align elements
//usage <div class="div-table"><div class="div-tablecell">copy</div></div>
.div-table { 
	display:table;
	height:100%;
	width:100%;
}
.div-tablecell { 
	display:table-cell;
	vertical-align:middle;
	height:100%;
	width:100%;
}

//socicon specials
.socicon { 
	@include transition-hover();
}
.socicon, 
.socicon:hover,  
.socicon_a,  
.socicon_a:hover { 
	text-decoration:none;
}




// bgimg
.bgimg {
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  -o-object-fit: cover;
  object-fit: cover;
  overflow: hidden;

  video {
    //this is the normal way
    //width:100%;
    //height:100%;
    //-o-object-fit: cover;
    //object-fit: cover;

    //this is the way that also includes IE
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    //our modules are always in landscape mode, never portrait
    //if portrait, we'd have width 100% and height auto
    height: 100%;
    width: auto;
    &.portrait {
      height: auto;
      width: 100%;
    }

    body.browser-safari &,
    body.browser-chrome & {
      width:100%;
      height:100%;
      -o-object-fit: cover;
      object-fit: cover;
      top:0;
      left:0;
      transform: translate(0,0);
    }

  }

  &.tl { background-position: left top ;}
  &.tc { background-position: center top;}
  &.tr { background-position: right top;}
  &.cl { background-position: left center;}
  &.cc { background-position: center center;}
  &.cr { background-position: right center ;}
  &.bl { background-position: left bottom;}
  &.bc { background-position: center bottom;}
  &.br { background-position: right bottom;}

}


//apply to img tag
.imghover {
  @include transition_all();
  display: block;
  opacity: 1;
  &:hover {
    opacity:0.9;
  }
}

//for using in @extend .material-icons
.material-icons {
  font-family: 'Material Icons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.abs00 {
  position: absolute;
  top:0;
  left:0;
}
.abs100 {
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height: 100%;
}