.film-block.cast {
  .items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px 40px;
    @media only screen and (max-width: $breakpoint-small) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: $breakpoint-smaller) {
      grid-template-columns: repeat(1, 1fr);
    }
    .item {
      .actor {
        @include font-oswald-medium();
        color:#fff;
        font-size: 30px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
      .role {
        @include font-helvetica-regular();
        color:#fff;
        font-size: 24px;
      }
    }
  }
}

