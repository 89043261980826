// SIZES
$headerTall:175px;
$headerSmall:120px;

//COLORS
$color-teal:#3E7A9B;
$color-teal-dk: #1a6289;
$color-teal-dk2: #083856;
$color-blue:#257DF9;
$color-blue-dk:#010E3A;
$color-red: #fd474f;
$color-error: $color-red;
