body.screenings {
  #all-listings {
    min-height: calc(100vh - 450px);
    .theater-row {
      margin:60px 0;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $color-teal;
      padding-bottom: 60px;
      @media only screen and (max-width: $breakpoint-small) {
        display: block;
        width: 100%;
      }
      &:last-child {
        border-bottom: none;
      }
      .col-theaters {
        width:40%;
        max-width: 500px;
        margin-right: 80px;
        @media only screen and (max-width: $breakpoint-small) {
          margin:0 0 40px 0;
          width: 100%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 40px 20px;
          .p1, .p2 {

          }
        }

      }
      .col-screenings {
        width:100%;

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 40px 40px;
        @media only screen and (max-width: 1300px) {
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 40px 20px;
        }

        .rsvp {
          display: inline-flex;
          align-items: center;
          .qaIcon {
            margin-left: 15px;
            text-align: center;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            span {
              color:#fff;
              @include font-helvetica-bold();
              display: block;
              margin:6px auto 0;
              font-size: 12px;
              line-height: 12px;
              width:100%;
            }
            .icon {
              display: inline-flex;
              width:18px;
              height: auto;
              img {
                width:100%;
              }
            }
          }
        }
      }
    }
  }

  .mobile-header-dropdowns {
    display: none;
    @media only screen and (max-width: $breakpoint-mobile-nav) {
      display: block;
    }
    padding-top: $headerSmall;
    z-index: 10;
    background-color: $color-blue-dk;
    .field-row {
      padding-bottom: 10px;
      display: block;
      .field-row-element {
        display: block;
        margin-bottom: 20px;
        width:100%;
        .lbl {
          display: block;
          margin-bottom: 5px;
        }
      }
    }

  }

} //body.screenings

button.general-button {
  @include transition-all();
  background-color: $color-teal-dk2;
  border: 1px solid $color-teal;
  @include font-helvetica-bold();
  color:#fff;
  font-size: 24px;
  padding:8px 25px;
  text-transform: uppercase;
  &.inverted {
    cursor: pointer;
    background-color: $color-teal;
    padding:5px 17px;
    font-size: 18px;
    &:hover {
      background-color: $color-teal-dk2;
    }
  }
  &.open {
    cursor: pointer;
    &:hover {
      background-color: $color-teal;
    }
  }
  &.closed {
    cursor: no-drop;
  }
  &.error {
    border: 1px solid $color-error;
  }

} //button.general-button

#overlay-rsvp {
  display: flex;
  align-items: center;

  #rsvp-error-messages {
    * {
      color: $color-red;
    }
  }
  .rsvp-inner {
    margin:40px;
    @media only screen and (max-width: $breakpoint-small) {
      margin:40px 0;
    }
  }
  .overlay-rsvp-wrapper {
    width:100%;
    .scrim {
      position: absolute;
    }
  }
  .sec {
    margin: 20px 0;
    &.sec-submit {
      margin: 20px 0;
    }
    &.sec-finalnote {
      margin: 30px 0 0
    }
    p {
      margin-bottom: 0;
      line-height: 1.35em;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .icon {
      position: absolute;
      top:5px;
      left:-23px;
      img {
        width:15px;
      }
    }
  }


  .columns {
    display: flex;
    justify-content: space-between;
    padding:55px 55px;
    @media only screen and (max-width: $breakpoint-small) {
      display: block;
      padding:20px 35px;
    }
    .col-left {
      width:40%;
      min-width: 200px;
      max-width: 400px;
      margin-right: 60px;
      margin-top: -10px;
      @media only screen and (max-width: $breakpoint-small) {
        width: 100%;
        max-width: unset;
      }
      .line {
        margin:15px 0 15px;
        width:100%;
        height: 1px;
        background-color: $color-teal;
        display: block;
      }
    }
    .col-right {
      width: 100%;
      .fields {
        @media only screen and (max-width: $breakpoint-small) {
          margin-top: 40px;
        }
      }
      .field-row {
        @media only screen and (max-width: $breakpoint-small) {
          display: block;
          .field-row-element {
            width: 100%;
            margin-bottom: 40px;
          }
        }
      }
    }
    .thanks {
      width:100%;
      min-height: 30vh;

      .line {
        margin:15px 0 25px;
        width:100%;
        height: 1px;
        background-color: $color-teal;
        display: block;
      }
      .rte {
        text-align: center;
        margin: 0 auto;
      }
    }

  }
}//overlay-rsvp
