/********************************************************
VARIABLES
********************************************************/
@mixin transition-hover() {
  -webkit-transition: opacity 350ms ease, color 350ms ease, background-color 350ms ease, box-shadow 350ms ease, border 350ms ease, width 350ms ease;
  -moz-transition: opacity 350ms ease, color 350ms ease, background-color 350ms ease, box-shadow 350ms ease, border 350ms ease, width 350ms ease;
  -o-transition: opacity 350ms ease, color 350ms ease, background-color 350ms ease, box-shadow 350ms ease, border 350ms ease, width 350ms ease;
  transition: opacity 350ms ease, color 350ms ease, background-color 350ms ease, box-shadow 350ms ease, border 350ms ease, width 350ms ease;
  @include willChange();
}

@mixin transition-color() {
  -webkit-transition: color 350ms ease, background-color 350ms ease, border-color 350ms ease;
  -moz-transition: color 350ms ease, background-color 350ms ease, border-color 350ms ease;
  -o-transition: color 350ms ease, background-color 350ms ease, border-color 350ms ease;
  transition: color 350ms ease, background-color 350ms ease, border-color 350ms ease;
  @include willChange();
}

@mixin transition-all() {
  -webkit-transition: all 350ms ease;
  -moz-transition: all 350ms ease;
  -o-transition: all 350ms ease;
  transition: all 350ms ease;
  @include willChange();
}

@mixin transition-opacity() {
  -webkit-transition: opacity 350ms ease;
  -moz-transition: opacity 350ms ease;
  -o-transition: opacity 350ms ease;
  transition: opacity 350ms ease;
  @include willChange();
}

/* @include transition-all-timems(350ms) */
@mixin transition-all-timems($timems) {
  -webkit-transition: all $timems ease;
  -moz-transition: all $timems ease;
  -o-transition: all $timems ease;
  transition: all $timems ease;
  @include willChange();
}


/* @include transition-delay-timems(350ms) */
@mixin transition-delay-timems($timems) {
  -webkit-transition-delay: $timems;
  -moz-transition-delay: $timems;
  -o-transition-delay: $timems;
  transition-delay: $timems;
  @include willChange();
}

//transition-type(left); no quotes
@mixin transition-type($property) {
  -webkit-transition: $property 350ms ease;
  -moz-transition: $property 350ms ease;
  -o-transition: $property 350ms ease;
  transition: $property 350ms ease;
  @include willChange();
}

/* ex transition-delay(350ms) */
@mixin transition-delay($timems) {
  -webkit-transition-delay: all $timems ease;
  -moz-transition-delay: all $timems ease;
  -o-transition-delay: all $timems ease;
  transition-delay: all $timems ease;
  @include willChange();
}

//@include calc( width, '100% - 32px');
@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin calc-important($property, $expression) {
  #{$property}: -moz-calc(#{$expression}) !important;
  #{$property}: -webkit-calc(#{$expression}) !important;
  #{$property}: calc(#{$expression}) !important;
}

@mixin pop-out-text-tight() {
  text-shadow: 0 0 10px #000;
}

@mixin pop-out-text() {
  text-shadow: 0 0 20px #000;
}

@mixin pop-out-text-subtle() {
  text-shadow: 0 0 40px rgba(0, 0, 0, 0.75);
}

@mixin pop-out-box() {
  box-shadow: 0 0 20px #000;
}

@mixin pop-out-box-subtle() {
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.25);
}

@mixin pop-out-box-subtlest() {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

@mixin pop-out-box-subtle-kill() {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

@mixin rotate-90cc() {
  /* Safari */
  -webkit-transform: rotate(-90deg);
  /* Firefox */
  -moz-transform: rotate(-90deg);
  /* IE */
  -ms-transform: rotate(-90deg);
  /* Opera */
  -o-transform: rotate(-90deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

/* applie transition-all to holder before, also transform-origin: center center; */
@mixin rotate-360() {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=4);
}


//@include underlinehover(#000,2px,-2px); //expand out from center
//@include underlinehover(#000,2px,-2px,'sideswiper'); //expand left to right

@mixin underlinehover($color,$size,$bottom,$mode:"center") {
  @include underlinehover-split-prehover($color, $size, $bottom, $mode);
  &:hover {
    @include underlinehover-split-hover($mode);
  }
}

@mixin underlinehover-split-prehover($color,$size,$bottom,$mode: "center") {
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: $size;
    bottom: $bottom;
    left: 0;
    background-color: $color;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 650ms ease 0s;
    transition: all 650ms ease 0s;
    @if $mode == 'sideswiper' {
      transform-origin: 0 0;
    } @else {
      transform-origin: 50% 50%;
    }

  }
}

@mixin underlinehover-split-hover($mode:'center') {
  &:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition: all 250ms ease 0s;
    transition: all 250ms ease 0s;
    @if $mode == 'sideswiper' {
      transform-origin: 0 0;
    } @else {
      transform-origin: 50% 50%;
    }
  }
}

@mixin underlinehover-generic($color,$mode:'sideswiper') {
  @include underlinehover($color, 2px, -2px, $mode);
}

@mixin underlinehoverkill() {
  &:before {
    display: none !important;
    visibility: hidden !important;
  }
}

@mixin fancy-lines-hover($borderStarter,$borderGlow) {
  cursor: pointer;
  border: 1px solid $borderStarter;
  overflow: hidden;

  @include transition-all();
  img, .hover-container-img {
    @include transition-all();
  }
  &::before, &::after {
    width: 100%;
    height: 100%;
    z-index: 3;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    transform: scale(0);
    transition: 0.7s;
  }
  &::before {
    border-bottom: 1px solid #fff;
    border-left: 1px solid #fff;
    transform-origin: 0 100%;
  }
  &::after {
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    transform-origin: 100% 0;
  }
  &:hover::before, &:hover::after {
    transform: scale(1);
  }
  &:hover {
    border: 1px solid transparent;
    box-shadow: 0 0 9px 1px $borderGlow, inset 0 0 9px 1px $borderGlow;

    img, .hover-container-img {
      transform: scale(1.03);
    }
  }


  /*
    @include transition-all();
    &:hover {
      opacity:0.85;
      .img-full {
        transform: scale(1.03);
      }
    }
    */


}

@mixin side-swipe-button-fill($defaultColor,$hoverColor) {
  background-color: $defaultColor;

  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: $hoverColor;
    transform-origin: left;
    transition: width 650ms ease;
    z-index: -1;
  }
  z-index: 0;

  &:hover {
    &::after {
      transition: width 350ms ease;
      width: 100%;
    }
  }
}


//@include text-outline(2px,#fff,rgba(255, 255, 255, 0));
@mixin text-outline($strokeWidth,$strokeColor, $fillColor,$failsafeColor:#fff) {
  color: $failsafeColor;
  -moz-text-fill-color: $fillColor;
  -webkit-text-fill-color: $fillColor;
  text-fill-color: $fillColor;
  -moz-text-stroke-color: $strokeColor;
  -webkit-text-stroke-color: $strokeColor;
  text-stroke-color: $strokeColor;
  -moz-text-stroke-width: $strokeWidth;
  -webkit-text-stroke-width: $strokeWidth;
  text-stroke-width: $strokeWidth;
}


@mixin set-input-placeholder-color($col) {
  &::-webkit-input-placeholder {
    color: $col
  }
  &:-moz-placeholder {
    color: $col;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: $col;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $col;
  }
  &::-ms-input-placeholder {
    color: $col;
  }
  &::placeholder {
    color: $col;
  }
}


@mixin placeholder {
  &::-webkit-input-placeholder {
    @content
  }
  &:-moz-placeholder {
    @content
  }
  &::-moz-placeholder {
    @content
  }
  &:-ms-input-placeholder {
    @content
  }
}


@mixin column-break-inside {
  -webkit-column-break-inside: avoid; /* Chrome, Safari */
  page-break-inside: avoid; /* Theoretically FF 20+ */
  break-inside: avoid-column; /* IE 11 */
}

@function get-vw($target) {
  $vw-context: (1700 *.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}


@mixin vh100 {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}

@mixin willChange {
  will-change: opacity, scale, filter, transform;
}
