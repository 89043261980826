.film-block.awards {
  .items {
    .item {
      border-bottom: 1px solid $color-teal;
      margin-bottom: 40px;
      padding-bottom: 40px;
      &:last-child {
        border-bottom: none;
      }

      .label {
        @include font-oswald-medium();
        color:#fff;
        font-size: 30px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
      .description {
        @include font-helvetica-regular();
        color:#fff;
        p {
          margin-bottom: 12px;
          font-size: 18px;
        }
      }


    }
  }
}

