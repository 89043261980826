
//LEAVE AT BOTTOM - desktop, mobile global hiders
.show-desktop {
  display: inline-block;
  &.block {
    display: block;
  }
  @media only screen and (max-width: $breakpoint-small) {
    display: none;
    &.block {
      display: none;
    }
  }
}
.show-mobile {
  display: none;
  &.block {
    display: none;
  }
  @media only screen and (max-width: $breakpoint-small) {
    display: inline-block;
    &.block {
      display: block;
    }
  }
}


.txt-address {
  a {
    text-decoration: none;
    &:hover {
      color: #fff;
    }
  }
}