#overlay,
.overlay-section {
  display: none;
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;

  #overlay-scrim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: block;
  }

  #overlay2,
  #overlay-inner,
  .overlay-section,
  .overlay-video-wrapper,
  .overlay-video-wrapper iframe,
  .overlay-video-wrapper video {
    width: 100%;
    height: 100%;
  }
  .overlay-section {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
  }

  #overlay-inner2 {
    @include calc(width, '100% - 200px');
    @include calc(height, '100% - 100px');
    margin: 50px 100px;
    @media only screen and (max-width: $breakpoint-small) {
      @include calc(width, '100% - 40px');
      @include calc(height, '100% - 40px');
      margin: 20px;
    }
  }


  .overlay-video-caption {
    position: absolute;
    bottom: -45px;
    left: 0;
    width: 100%;
    @include font-oswald-medium();
    font-size: 22px;
    color: $color-blue;
  }
}

.overlay-x {
  position: absolute;
  z-index: 20;
  right: 0;
  top: 0;
}


// special screenings overlay
body.screenings-scroller {
  #overlay {
    position: absolute;
    #overlay-inner {
      height: auto;
    }
    #overlay-rsvp {
      display: block;
    }
  }

}
