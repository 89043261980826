$mobilenav_hamburger_off:#fff;
$mobilenav_hamburger_on:#fff;

$headerHeightSmall:0;
#header-mobile,
#mobile-nav-overlay-outer,
#mobile-nav-hamburger {
  display:none;
}
@media only screen and (max-width: $breakpoint-mobile-nav) {
  #mobile-nav-overlay-outer {
    display: block;
    //position: fixed;
    //top: $headerHeightSmall;
    //left:0;
    width:100%;
    z-index: 1000;
    overflow: hidden;
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 2px solid $color-teal;

    &.hidden {
      border-bottom: none;
      display: none;
    }
  }
  #header-mobile {
    display:block;
  }
  .header-mobile {
    display:block;
    position:absolute;
    width:70px;
    height:50px;
    z-index:5000;
    top:12px;
    right:5px;

  }
  #mobile-nav-inner {
    padding:0;
  }
  #mobile-nav-hamburger {
    display:block;
    position:absolute;
    top:40px;
    right:20px;
    z-index:2;
  }
  .hamburger {
    padding:5px;
  }
  .hamburger-inner,
  .hamburger-inner::after,
  .hamburger-inner::before {
    background-color:$mobilenav_hamburger_off;
    border-radius: 0;
  }
  .is-active .hamburger-inner,
  .is-active .hamburger-inner::after,
  .is-active .hamburger-inner::before {
    background-color:$mobilenav_hamburger_on !important;
  }

  #mobile-nav-overlay {
    //background-color: #000;
    //border-top: 4px solid $color-blue-dk;
    //border-bottom: 4px solid $color-blue-dk;
    @include master-padding-lr-width();
    position:relative;
    @include transition_all();
    display: block;
    &.hidden {
      display: none;
    }
    nav {
      padding:20px;
    }

    @media only screen and (max-width: $breakpoint-mobile-nav) {
      .lang-switcher {
        display: none;
      }
    }


    nav ul {
      margin: 10px 0;
      li {
        list-style: none;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
        .item-on {
          display: none;
        }
        &.action-li {
          //margin-top: 30px;
        }
        .action-hands {
          @media only screen and (max-width: $breakpoint-mobile-nav) {
            img {
              position: absolute;
              width: 38px;
              top:-20px;
              left:120px;
              body.es-us & {
                left:135px;
              }
              margin-right: 5px;
              vertical-align: text-bottom;
            }
          }
        }
        a {
          @include font-oswald-medium();
          font-size:16px;
          @include transition_all();
          //@include underlinehover-generic($color-red,'sideswiper');
          &:hover {
            //color:$color-red;
          }
        }
        &.li-level1 {
          border-bottom: 1px solid #fff;
          a {
            color:#fff;
            &.active {
              color:$color-red;
            }
          }
          &.search {
            border-bottom: 0 solid #fff;
          }
        }
        &.li-level2 {
          a {
            color:#fff;
            &.active {
              color:$color-red;
            }
          }
          //border-bottom: 1px solid #fff;
        }
        .hover-holder-level2 {
          padding:0 20px;
        }
        #nav-search {
          margin-top: 30px;
          input {
            @include calc(width,'100% - 20px');
            padding:5px 10px;
            @include font-oswald-medium();
            font-size:16px;
          }
          input.search-submit {
            background-color: transparent;
            position: absolute;
            top:1px;
            right:0;
            cursor: pointer;
            width:15px;
          }

        }
      }//li
    }

    .mobile-search {
      margin: 20px 0 0;
    }
    .button {
      margin:20px 0 0;
      .global-button {
        @include calc(width,'100% - 8px');
        text-align: center;
      }
    }

  }//mobile-nav-overlay


  .mobile-content {
    width:70%;
    text-align: center;
    margin:0 auto;
    h5 {
      @include font-montserrat-bold();
      font-size: 16px;
      color:#B9B9B9;
      margin: 50px 0 20px;
      &:first-child {
        margin-top: 20px;
      }
      &:before, &:after {
        content:"";
        display: block;
        height: 1px;
        width: 80px;
        margin:0 auto;
        background-color: $color-teal;
      }
      &:before {
        margin-bottom: 20px;
      }
      &:after {
        margin-top: 20px;
      }
    }

    ul {
      list-style: none;
      li {
        margin-bottom: 15px;
        a {
          color:#fff;
          @include font-oswald-light();
          font-size: 24px;
          text-transform: uppercase;
        }

      }
    }
  }
}
